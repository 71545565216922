import React from "react"
import {
  FaLinkedin,
  FaGithubSquare
} from "react-icons/fa"

const data = [
  {
    id: 1,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/in/amirhussainnet",
    name: "LinkedIn"
  },
  {
    id: 2,
    icon: <FaGithubSquare className="social-icon"></FaGithubSquare>,
    url: "https://github.com/AmirHuxain",
    name: "Github"
  }
]

export default data
