exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-category-strapi-category-slug-js": () => import("./../../../src/pages/blog/category/{StrapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-blog-category-strapi-category-slug-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-strapi-blog-slug-js": () => import("./../../../src/pages/blog/{StrapiBlog.slug}.js" /* webpackChunkName: "component---src-pages-blog-strapi-blog-slug-js" */),
  "component---src-pages-contact-backup-js": () => import("./../../../src/pages/contact_backup.js" /* webpackChunkName: "component---src-pages-contact-backup-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-strapi-project-slug-js": () => import("./../../../src/pages/projects/{StrapiProject.slug}.js" /* webpackChunkName: "component---src-pages-projects-strapi-project-slug-js" */)
}

